<template lang="pug">
    .bootcamp-pricing#pricing
        v-card.pa-5(flat)
            h2.text-center.mb-5 Pricing
            v-row(align="center")
                v-col(cols="12", md="6")
                    v-card(color="grey lighten-4" flat shaped).px-2.pa-md-5.my-5.mr-md-12
                        v-row(v-for="item in items", :key="item")
                            v-col(cols="2" md="1")
                                v-icon(color="success" large) mdi-check
                            v-col(cols="10" md="11") {{item}}
                v-col(cols="12", md="6")
                    h3.text-center Upgrade your professional IT English from Elementary to Pre-Intermediate in just 6 weeks
                    .prices#prices.d-flex.text-center.justify-center.mt-7.mb-12.flex-column.flex-md-row
                        .d-flex.justify-center
                            .wrong-price.anno $1,290
                        .price $990 / 6 weeks
                        .description.ml-3 + textbook
                    v-btn(color="success" block depressed x-large).font-weight-bold Register Now
                        v-icon.ml-2 mdi-arrow-right
                    p.text-center.my-3 Our next cohort starts on February 1, 2020

</template>

<script>
import { annotate } from 'rough-notation'
export default {
  name: 'BootCampPricing',
  data() {
    return {
      items: [
        '6 week Bootcamp',
        '3 lessons per week',
        '90 minutes of class time per lesson',
        '1 ~ 3 hours of prep time per lesson',
        'Unlimited communication with both trainers during Bootcamp',
        'Text us questions before + after lessons',
        'Membership to our private Bootcamp Slack group'
      ]
    }
  },
  mounted() {
    setTimeout(() => {
      this.doAnnotations()
    }, 2500)
  },
  methods: {
    doAnnotations() {
      const e1 = document.querySelector('.anno.wrong-price')
      if (e1) {
        const annotation = annotate(e1, {
          type: 'strike-through',
          multiline: false,
          color: 'grey'
        })
        annotation.show()
      }

      const e2 = document.querySelector('.prices')
      if (e1) {
        const annotation = annotate(e2, {
          type: 'box',
          multiline: true,
          color: this.$vuetify.theme.themes.light.success,
          padding: [20]
        })
        annotation.show()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-application.static {
     .wrong-price {
      color: gray;
      opacity: 0.75;
      font-size: 1.3rem;
    }

      .price {
          font-weight:bold;
        font-size: 2rem;
        line-height:1;
      }

  @media only screen and (min-width: 600px) {
    .wrong-price {
      font-size: 1.3rem;
    }

      .price {
          font-weight:bold;
        font-size: 2rem;
        line-height:1;
      }
  }
}
</style>
